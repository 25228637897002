(function () {
  angular.module('kmi.lms.search').run(moduleRun);

  /* @ngInject */
  function moduleRun(searchFilters, searchFilterObject, searchFilterCourseLabel, searchFilterCourseProviders) {
    searchFilters.onInit(onInit);

    function onInit() {
      var objectFilter = searchFilters.getFilter(searchFilterObject),
        tagFilter = new searchFilters.TagFilter(),
        courseFormatFilter = new searchFilters.CourseFormatFilter(),
        courseFormatTypeFilter = new searchFilters.CourseFormatTypeFilter(),
        courseFormatLabel = searchFilters.getFilter(searchFilterCourseLabel),
        subjectFilter = new searchFilters.SubjectFilter(),
        creditTypeFilter = new searchFilters.CreditTypeFilter(),
        // competencyFilter = new searchFilters.CompetencyFilter(),
        lsFilter = new searchFilters.LsFilter(),
        targetAudiencesFilter = new searchFilters.TargetAudienceFilter({ label: 'Target Audience' }),
        filterOrganization = searchFilters.getFilter(
          searchFilterCourseProviders.getOrganizationFilterConfig({ mode: 'promptingFilter' }),
        );

      courseFormatLabel.label = 'Format';

      searchFilters.associateFilters(objectFilter, courseFormatLabel);
      searchFilters.associateFilters(objectFilter, filterOrganization);

      return {
        ui: [objectFilter, courseFormatLabel, subjectFilter, targetAudiencesFilter, filterOrganization],
        advanced: [],
        all: [
          objectFilter,
          tagFilter,
          courseFormatFilter,
          courseFormatLabel,
          subjectFilter,
          targetAudiencesFilter,
          creditTypeFilter,
          /* competencyFilter, */ lsFilter,
          courseFormatTypeFilter,
          filterOrganization,
        ],
      };
    }
  }
})();
