(function () {
  angular.module('kmi.lms.kmiacademy.authorization', ['globalDependencies', 'kmi.lms.portals']).config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider.state('prompt.portalCompliance', {
      url: '/portal_compliance',
      component: 'portalComplianceComponent',
      data: {
        label: 'Portal Compliance',
        availableFor: ['regularUser', 'admin'],
      },
    });
  }
})();
