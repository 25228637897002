(function () {
  angular.module('kmi.lms.kmiacademy.authorization').component('portalComplianceComponent', {
    template: require('ajs/custom_modules/kmiacademy/authorization/portal-compliance.html').default,
    controller: PortalComplianceController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
    },
  });

  /* @ngInject */
  function PortalComplianceController(
    $window,
    $state,
    navigationService,
    portalsService,
    globalConfig,
    elmsAuthService,
    _,
  ) {
    var vm = this;

    vm.navigateToPortal = navigateToPortal;

    activate();

    function activate() {
      vm.loading = true;

      portalsService
        .getUserPortals()
        .then(function (portals) {
          vm.portals = portals;

          if (vm.portals.length) {
            vm.targetPortal = vm.portals[0];
            navigateToPortal();
          }
        })
        .finally(function () {
          vm.loading = null;
        });
    }

    function navigateToPortal() {
      if (vm.targetPortal) {
        if (vm.targetPortal.domainName !== $window.location.hostname) {
          elmsAuthService.logout().then(function () {
            redirectToPortal();
          });
        } else {
          redirectToPortal();
        }
      }
    }

    function redirectToPortal() {
      // Deep linking support. Pass target path to the new portal
      // Skip current state in deep linking
      var targetState = navigationService.getTargetState();
      var targetPath =
        targetState && $state.current && targetState.name === $state.current.name
          ? null
          : navigationService.getRelativeTargetPath();
      var targetPortal = portalsService.getPortalPath(vm.targetPortal);

      $window.location.href = targetPath
        ? _.trimEnd(targetPortal, '/') + '/' + targetPath.replace(globalConfig.base, '')
        : targetPortal;
    }
  }
})();
